<template>
  <div class="traceability">
    <img style="width: 100%" src="@/assets/banner.png" />
    <div v-if="!empty" class="traceability-centent">
      <div class="traceability-manufacturer">
        <div class="img-title">厂商信息</div>
        <div v-for="(item, index) in businessData" :key="index">
          <!-- 厂商信息 -->
          <div>
            <div class="main">
              <div class="line">
                <div class="garden"></div>
                <div class="myline"></div>
                <div class="garden"></div>
              </div>
              <div class="title">
                <span>厂商名称：</span>
                <span>产品登记时间：</span>
              </div>
              <div class="text">
                <div>{{ item.loginName }}</div>
                <div>{{ item.createTime }}</div>
              </div>
            </div>
          </div>
          <!-- 分界线 -->
          <!--<div class="traceability-manufacturer-dividingLine">
            <div class="num-line"></div>
            <span>{{ index + 1 }}</span>
            <div class="num-line"></div>
          </div>-->
        </div>
        <!--<div v-if="flag" class="traceability-manufacturer-btn" @click="addData">
          <a-icon type="plus" />
          <span>追溯上家厂商信息</span>
        </div>-->
      </div>
      <!-- 产品信息 -->
      <div v-if="myData" class="traceability-information">
        <div class="img-title">产品基本信息</div>
        <div>
          <el-steps direction="vertical" space="100px" finish-status="success">
            <el-step>
              <template #icon>
                <i>&nbsp;</i>
              </template>
              <template #title>
                <div class="my-title">产品名称：{{ myData.name }}</div>
              </template>
            </el-step>
            <el-step>
              <template #icon>
                <i>&nbsp;</i>
              </template>
              <template #title>
                <div class="my-title">规格：{{ myData.standard }}</div>
              </template>
            </el-step>
            <el-step>
              <template #icon>
                <i>&nbsp;</i>
              </template>
              <template #title>
                <div class="my-title">型号：{{ myData.model }}</div>
              </template>
            </el-step>
            <el-step>
              <template #icon>
                <i>&nbsp;</i>
              </template>
              <template #title>
                <div class="my-title">单位：{{ myData.materialUnit }}</div>
              </template>
            </el-step>
            <el-step>
              <template #icon>
                <i>&nbsp;</i>
              </template>
              <template #title>
                <div class="my-title">颜色：{{ myData.color }}</div>
              </template>
            </el-step>
            <el-step>
              <template #icon>
                <i>&nbsp;</i>
              </template>
              <template #title>
                <div class="my-title">基础重量：{{ myData.weight }}</div>
              </template>
            </el-step>
            <el-step>
              <template #icon>
                <i>&nbsp;</i>
              </template>
              <template #title>
                <div class="my-title">序列号：{{ myData.snList }}</div>
              </template>
            </el-step>
            <el-step>
              <template #icon>
                <i>&nbsp;</i>
              </template>
              <template #title>
                <div class="my-title">产品条码：{{ myData.barCode }}</div>
              </template>
            </el-step>
            <el-step>
              <template #icon>
                <i>&nbsp;</i>
              </template>
              <template #title>
                <div class="my-title">工业溯源码：{{ myData.handle }}</div>
              </template>
            </el-step>
          </el-steps>
        </div>
      </div>
    </div>
    <img v-else class="traceability-empty" src="@/assets/empty.png" />
  </div>
</template>
<script>
import {getTraceabilityDetail} from './api.js';
export default {
  data() {
    return {
      businessData: [],
      myData: {},
      flag: true,
      empty: true,
      params: {
        nodeCode: ''
      }
    };
  },
  created() {
    this.$set(this.params, 'nodeCode', this.$route.query.handle);
    this.getData();
  },
  mounted() {},
  watch: {
    empty: function (val) {
      if (!val) {
        document.querySelector('body').setAttribute('style', 'background-color:#e9eef4');
      } else {
        document.querySelector('body').setAttribute('style', 'background-color:#fff');
      }
    }
  },
  methods: {
    async getData() {
      let res = await getTraceabilityDetail(this.params);
      if (res.errno === 0) {
        this.myData = res.data;
        this.empty = false;
        if (res.data != null) {
          this.businessData.push(res.data);
        }
      } else {
        this.empty = true;
      }
    },
    addData() {
      if (this.flag) this.getData();
    }
  }
};
</script>
<style>
/* body {
  background-color: #e9eef4;
} */
</style>
<style lang="less" scoped>
.traceability {
  font-size: 17px;
  .my-title {
    font-weight: 600;
    color: #000;
  }
  &-centent {
    padding-top: 50px;
    width: 41%;
    margin: 0 auto;
  }
  &-manufacturer {
    position: relative;
    padding: 65px 30px 28px 30px;
    border-radius: 5px;
    background-color: #fff;
    .img-title {
      font-size: 20px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 213px;
      height: 42px;
      background: url('../../assets/title-copy.png') no-repeat;
      position: absolute;
      left: calc(50% - 106px);
      top: -10px;
    }
    .main {
      display: flex;
      height: 80px;
    }
    .line {
      padding: 5px 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .garden {
        width: 10px;
        height: 10px;
        border-radius: 50px;
        border: 2px solid #2697ff;
      }
      .myline {
        width: 0;
        height: calc(100% - 23px);
        border: 1px dashed #2697ff;
      }
    }
    .title {
      font-weight: 600;
    }
    .text {
      color: #666666;
    }
    .title,
    .text {
      margin: 0 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    // 分界线
    &-dividingLine {
      margin: 25px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .num-line {
        height: 0;
        width: calc(50% - 20px);
        border: 1px solid #e6e6e6;
      }
    }
    &-btn {
      color: #fff;
      margin: 40px auto 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 210px;
      height: 43px;
      border-radius: 50px;
      background-color: #1890ff;
    }
  }
  // 产品信息
  &-information {
    margin-top: 52px;
    position: relative;
    padding: 65px 30px 28px 30px;
    border-radius: 5px;
    background-color: #fff;
    & ::v-deep .el-step__line {
      background-color: #2697ff;
    }
    & ::v-deep .el-step__icon {
      border-color: #2697ff;
    }

    .img-title {
      font-size: 20px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 213px;
      height: 42px;
      background: url('../../assets/title-copy.png') no-repeat;
      position: absolute;
      left: calc(50% - 106px);
      top: -10px;
    }
    .main {
      display: flex;
      height: 80px;
    }
    .line {
      padding: 5px 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .garden {
        width: 10px;
        height: 10px;
        border-radius: 50px;
        border: 2px solid #2697ff;
      }
      .myline {
        width: 0;
        height: calc(100% - 23px);
        border: 1px dashed #2697ff;
      }
    }
  }
  &-empty {
    position: absolute;
    top: calc(50% - 111px);
    left: calc(50% - 169px);
  }
}
</style>
